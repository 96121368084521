import { Component, OnInit, ViewChild } from '@angular/core';
import { RacesService } from '@services/races.service';
import { ExcelService } from '@services/excel.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { error } from 'jquery';
import { ToastrService } from 'ngx-toastr';

@Component({
	selector: 'app-races-overview',
	templateUrl: './races-overview.component.html',
	styleUrls: ['./races-overview.component.css'],
})
export class RacesOverviewComponent implements OnInit {
	@ViewChild('emailModal') emailModal;

	perPage = 10;
	currentPage = 1;

	originalRows = [];
	rows = [];

	filter = {
		dealer_id: '',
		masteragent_id: '',
		agent_id: '',
		channel: '',
		confirmation: '',
		month: new Date().getMonth() + 1,
		year: new Date().getFullYear(),
	};

	months = [
		{ id: 1, name: 'Gennaio' },
		{ id: 2, name: 'Febbraio' },
		{ id: 3, name: 'Marzo' },
		{ id: 4, name: 'Aprile' },
		{ id: 5, name: 'Maggio' },
		{ id: 6, name: 'Giugno' },
		{ id: 7, name: 'Luglio' },
		{ id: 8, name: 'Agosto' },
		{ id: 9, name: 'Settembre' },
		{ id: 10, name: 'Ottobre' },
		{ id: 11, name: 'Novembre' },
		{ id: 12, name: 'Dicembre' },
	];

	years = [
		{ id: 2024, name: '2024' },
		{ id: 2025, name: '2025' },
		{ id: 2026, name: '2026' },
		{ id: 2027, name: '2027' },
	];

	loading = false;

	emailData: { to: string; subject: string; body: string } = null;

	selectedRow: any;
	constructor(
		private raceService: RacesService,
		private excelService: ExcelService,
		public modalService: NgbModal,
		private spinner: NgxSpinnerService,
		private toastr: ToastrService
	) {}

	ngOnInit(): void {
		this.refresh();
	}

	refresh() {
		this.loading = true;
		const params = {};

		if (this.filter.masteragent_id) {
			params['masteragent_id'] = this.filter.masteragent_id;
		}
		if (this.filter.agent_id) {
			params['agent_id'] = this.filter.agent_id;
		}
		if (this.filter.dealer_id) {
			params['dealer_id'] = this.filter.dealer_id;
		}
		if (this.filter.channel) {
			params['channel'] = this.filter.channel;
		}
		if (this.filter.confirmation) {
			params['confirmation'] = this.filter.confirmation;
		}

		params['month'] = this.filter.month;
		params['year'] = this.filter.year;

		this.raceService.getOverview(params).subscribe(
			(response) => {
				this.originalRows = response;
				this.preparePagination();
				this.loading = false;
			},
			(error) => {
				this.loading = false;
			}
		);
	}

	preparePagination() {
		this.rows = this.originalRows.slice(
			(this.currentPage - 1) * this.perPage,
			this.currentPage * this.perPage
		);
	}

	exportExcel() {
		let data = this.originalRows.map((row) => {
			return {
				'Login Code': row.login_code,
				Negozio: row.company_name,
				Sottocanale: row.subchannel,
				'District Manager': row.district,
				'Regional Manager': row.regional,
				Gara: row.race,
				'Totale Ordini Validi': row.total_valid_orders,
				'Totale Ordini Annullati': row.total_invalid_orders,
				'Totale Ordini in Gara': row.total_race_orders,
				'Soglia Ordini': row.range,
				'Ordini alla prossima soglia': row.missing_range_orders,
				'Conferma Partecipazione': row.confirmed,
			};
		});
		this.excelService.exportAsExcelFile(data, 'Avanzamento Gare');
	}

	previewMail(row) {
		this.selectedRow = row;
		this.modalService.open(this.emailModal, { size: 'lg' });
		this.spinner.show('email');
		this.raceService.getEmail(row).subscribe(
			(res) => {
				this.emailData = res;
				this.spinner.hide('email');
			},
			(error) => {
				this.spinner.hide('email');
				console.log('error', error);
			}
		);
	}

	sendMail(data) {
		this.spinner.show('email');
		this.raceService.sendEmail(data).subscribe(
			(res) => {
				if (res.status === 'success') {
					this.spinner.hide('email');
					this.modalService.dismissAll();
					this.refresh();
					this.toastr.success('Email inviata con successo!', 'Operazione completata', {
						positionClass: 'toast-bottom-right',
						progressBar: true,
						progressAnimation: 'increasing',
					});
				} else {
					this.spinner.hide('email');
					this.modalService.dismissAll();
					this.refresh();
					this.toastr.error("Impossibile inviare l'email, contattare il supporto", 'Errore', {
						positionClass: 'toast-bottom-right',
						progressBar: true,
						progressAnimation: 'increasing',
					});
				}
			},
			(error) => {
				this.spinner.hide('email');
				console.log('error', error);
			}
		);
	}
}
