<div class="card bg-gradient-1">
	<div class="card-body">
		<div class="d-flex align-items-lg-center">
			<img
				class="d-none d-xl-inline-flex img-circle bg-gradient-light p-1 elevation-1 ng-star-inserted"
				src="assets/img/flats/placeholder.png"
				width="160px"
			/>
			<div class="d-block w-100 pl-lg-3">
				<h1 class="display-4" translate>Confronto tra province</h1>
				<hr />
				<div class="form-row align-items-end">
					<div class="col-lg-3 col-12">
						<label translate>Operatore</label>
						<app-vendor-select
							(onChange)="changeVendor($event)"
							[outputLabel]="true"
							[pickFirst]="true"
						></app-vendor-select>
					</div>
					<div class="col-lg-3 col-12">
						<label for="date" translate>Date</label>
						<input
							[(ngModel)]="this.filter.dateOutput"
							class="form-control"
							(selected)="setSelectedDateRange($event.start, $event.end)"
							daterangepicker
							id="date"
							name="date"
							type="text"
						/>
					</div>
					<div *show="['master', 'operator', 'submaster']" class="col-lg-3 col-12">
						<label for="date" translate>Master Agent</label>
						<app-master-agent-select
							(onChange)="filter.masteragent_id = $event"
						></app-master-agent-select>
					</div>
					<div *show="['masteragent', 'master', 'operator', 'submaster']" class="col-lg-3 col-12">
						<label for="date" translate>Agent</label>
						<app-agent-select
							[masteragentId]="filter.masteragent_id"
							(onChange)="filter.agent_id = $event"
						></app-agent-select>
					</div>
					<div class="col-lg-3 col-12">
						<label for="region" translate>Region</label>
						<app-state-select (onChange)="changeRegion($event)" id="region"></app-state-select>
					</div>
					<div class="col-lg-3 col-12">
						<label for="city" translate>Sottocanale</label>
						<app-channel-select (onChange)="this.filter.channel = $event"></app-channel-select>
					</div>
					<!-- <div class="col-lg-3 col-12"><label for="city" translate>Cluster Comune</label>
                        <app-cluster-select
                                (onChange)="this.filter.cluster = $event"
                        ></app-cluster-select>
                    </div> -->
					<div class="col-lg-3 col-12 mt-2 mt-lg-auto">
						<button (click)="loadData()" class="btn btn-light btn-block" translate type="submit">
							Filter
						</button>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>

<app-spinner *ngIf="isLoading" status="loading"></app-spinner>

<div class="row" *ngIf="!isLoading">
	<div class="col-4" *ngFor="let regione of regioni">
		<div class="card bg-gradient-light elevation-1">
			<div class="card-body">
				<h1 class="display-6">{{ regione.regione }}</h1>
				<div class="table-responsive table-borderless rounded">
					<app-table
						[hideFooter]="true"
						[striped]="true"
						[hover]="true"
						[small]="true"
						[rows]="regione.table.rows"
						[columns]="regione.table.columns"
					></app-table>
				</div>
				<h1 class="display-6 mt-2">
					Totale vendite:
					<span class="float-right">{{ regione.sim }}</span>
				</h1>
			</div>
		</div>
	</div>
</div>
